import type { OptionalBreakpointKey, RequiredBreakpointKey } from "scmp-app/lib/styles/responsive";

export const JWTExpiryThreshold = 1800; // Seconds
export const JWTRefreshSWRKey = "refresh-jwt";

export const DefaultHeaderSwapThreshold = 150;

export const HeaderArticleSpeechContainerElementID = "header-article-speech-container";

export const IconMenuResponsiveVariants = {
  desktopUp: "show",
  homeDesktopUp: "hidden",
  largeDesktopUp: "hidden",
  mobileUp: "show",
  tabletUp: "show",
} as const;

export const Breakpoints: Array<RequiredBreakpointKey | OptionalBreakpointKey> = [
  "desktopUp",
  "homeDesktopUp",
  "largeDesktopUp",
  "mediumDesktopUp",
  "mobileUp",
  "tabletUp",
];
